<template>
  <ContainerWithMenu :loading="loading">
    <div class="page-content hide-print">
      <div class="row">
        <div class="col-xs-9">
          <h1 class="h2 margin-bottom-20">Prenotazione #<span id="reservation_id">{{this.reservation_id}}</span> <span class="label" :class="LabelClassHelper.getLabelClassReservationState(reservation.state)">{{StringsHelper.getReservationState(reservation.state)}}</span>
          </h1>
        </div>
        <div class="col-xs-3 text-right">
          <router-link :to="{name:'ticket', params: {reservation_id: reservation.id}}" target="_blank" class="btn btn-default btn-sm" ><span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa biglietto</router-link>
        </div>
      </div>
      <p class="margin-bottom-20">Ticket Numero <span class="label label-primary" id="ticket_barcode">{{reservation.ticket_code}}</span></p>
      <div class="row">
        <div class="col-xs-6">
          <div class="booking-section">
            <h4>Sito</h4>
            <div id="product_list" v-for="product in reservation.product_list" :key="product"><p>{{StringsHelper.getProduct(product)}}</p></div>
          </div>
          <div class="booking-section">
            <h4 style="margin-bottom:20px;">Data e Orario</h4>
            <div class="row section-editable" v-show="updatingReservation">
              <div class="col-xs-12">
                <div class="form-group has-feedback">
                  <label class="control-label" for="reservation_date_edit">Data e Orario</label>
                    <Datetime
                            type="datetime"
                            :value-zone="TIMEZONE_DEFAULT"
                            :zone="TIMEZONE_DEFAULT"
                            v-model="reservation.date"
                            input-class="form-control"
                    ></Datetime>
                  <span class="glyphicon glyphicon-calendar form-control-feedback" aria-hidden="true"></span>
                </div>
              </div>

            </div>
            <div class="row section-not-editable" v-show="!updatingReservation">
              <div class="col-xs-6">
                <div class="form-group has-feedback">
                  <label class="control-label" for="reservation_date">Data e Ora</label>
                  <p class="margin-0"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> <span id="reservation_date">{{Utils.getDateStringFromISO(reservation.date)}}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-section">
            <h4>Informazioni Cliente</h4>
            <div class="row section-editable" v-show="updatingReservation">
              <div class="col-xs-12 margin-bottom-15">
                <div class="form-group">
                  <label for="customer_nationality_edit">Nazione*</label>
                    <SelectCountry v-if="reservation.customer_nationality" @country-changed="countryChanged" :default-country="reservation.customer_nationality"></SelectCountry>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="customer_first_name_edit">Nome</label>
                  <input type="text" class="form-control" id="customer_first_name_edit" v-model="reservation.customer_first_name">
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="customer_last_name_edit">Cognome</label>
                  <input type="text" class="form-control" id="customer_last_name_edit" v-model="reservation.customer_last_name">
                </div>
              </div>
              <div class="col-xs-12 margin-top-20 cancel_reason_section" v-show="reservation.state === Keys.RESERVATION_STATES.CANCELED">
                <div class="form-group">
                  <label for="cancel_reason_edit">Motivazione annullamento*</label>
                  <textarea class="form-control" id="cancel_reason_edit" rows="3" maxlength="255" v-model="reservation.cancel_reason"></textarea>
                </div>
              </div>
                <div class="col-xs-12 margin-top-20">
                    <div class="form-group">
                        <label for="customer_email_edit">Email</label>
                        <input type="email" class="form-control" id="customer_email_edit" v-model="reservation.customer_email">
                    </div>
                    <div class="form-group">
                        <label for="customer_phone_edit">Telefono</label>
                        <VuePhoneNumberInput
                                @update="updatePhoneNumber"
                                no-validator-state
                                default-country-code="IT"
                                v-model="reservation.customer_phone"
                                :translations="Utils.getTranslationsPhoneField()"
                                id="phone"></VuePhoneNumberInput>
                    </div>
                </div>
            </div>
            <div class="row section-not-editable" v-show="!updatingReservation">
              <div class="col-xs-12">
                <div class="form-group">
                  <label for="customer_nationality">Nazione*</label>
                  <p id="customer_nationality">{{this.COUNTRIES[reservation.customer_nationality]}}</p>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="customer_first_name">Nome</label>
                  <p v-if="reservation.customer_first_name != null" id="customer_first_name">{{reservation.customer_first_name}}</p>
                  <p v-else>NA</p>
                </div>
                <div class="form-group">
                  <label for="customer_email">Email</label>
                  <p v-if="reservation.customer_email != null" id="customer_email">{{reservation.customer_email}}</p>
                  <p v-else>NA</p>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="customer_last_name">Cognome</label>
                  <p v-if="reservation.customer_last_name != null" id="customer_last_name">{{reservation.customer_last_name}}</p>
                  <p v-else>NA</p>
                </div>
                <div class="form-group">
                  <label for="customer_phone">Telefono</label>
                  <p v-if="reservation.customer_phone != null" id="customer_phone">{{reservation.customer_phone}}</p>
                  <p v-else>NA</p>
                </div>
              </div>
              <div class="col-xs-12 cancel_reason_section"  v-show="reservation.state === Keys.RESERVATION_STATES.CANCELED">
                <div class="form-group">
                  <label for="cancel_reason">Motivazione annullamento</label>
                  <p id="cancel_reason">{{StringsHelper.getStringOrNA(reservation.cancel_reason)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="panel panel-default">
            <div class="panel-heading">Cassa</div>
            <div class="panel-body">
              <CashSummaryTable v-if="reservation" :estimate-reservation="reservation"></CashSummaryTable>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">Pagamento</div>
            <div class="panel-body">
              <p class="section-editable" v-show="updatingReservation">
                <label for="payment_mode_edit">Metodo di pagamento</label>
                <select id="payment_mode_edit" class="form-control" v-model.number="reservation.payment_mode">
                    <option v-for="payment in paymentOptions" :key="payment.value" :value="payment.value">{{payment.name}}</option>
                </select>
              </p>
              <p>
                <span class="section-not-editable" v-show="!updatingReservation" id="payment_mode">{{StringsHelper.getPaymentMode(reservation.payment_mode)}}</span>  il <span id="created_date">{{Utils.getShortDateStringFromISO(reservation.purchase_date)}}</span> alle ore <span id="created_hour">{{Utils.getHourStringFromISO(reservation.purchase_date)}}</span>.</p>
              <p>Prenotazione eseguita da <u v-if="reservation.creator" id="creator_name">{{reservation.creator.display_name}}</u>.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6 text-center">
              <a id="edit-reservation-btn" v-show="!updatingReservation" class="btn btn-default btn-block" @click.prevent.stop="updatingReservation=true"><span class="glyphicon glyphicon-edit" aria-hidden="true"></span> Modifica Prenotazione</a>
              <a id="save-reservation-btn" v-show="updatingReservation" class="btn btn-success btn-block margin-0" style="display:none;" @click.prevent.stop="updateReservation"><span class="glyphicon glyphicon-check" aria-hidden="true"></span> Salva Modifiche</a>
            </div>
            <div class="col-xs-6 text-center" id="cancel-reservation-section" v-if="reservation.state !== Keys.RESERVATION_STATES.CANCELED">
              <a class="btn btn-danger btn-block" href="javascript:;" data-toggle="modal" data-target="#CancelReservationModal"  @click.prevent.stop="visibleCancelReservationModal=true"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Annulla Prenotazione</a>
            </div>
          </div>
        </div>
      </div>
    </div>
      <CancelReservationModal :visible="visibleCancelReservationModal"
                              @close-cancel-reservation-modal="visibleCancelReservationModal=false"
                              @positive-btn-cancel-reservation-modal="cancelReservation"
      ></CancelReservationModal>
  </ContainerWithMenu>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
  import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {cancelReservation, getReservationDetail, updateReservation} from "../api";
  import baseMixin from "../common/baseMixin";
  import {COUNTRIES} from "../common/countries";
  import CashSummaryTable from "../components/Booking/CashSummaryTable";
  import SelectCountry from "../components/common/SelectCountry";
  import {TIMEZONE_DEFAULT} from "../common/constants";
  import {Datetime} from "vue-datetime";
  import 'vue-datetime/dist/vue-datetime.css'
    import CancelReservationModal from "../components/modal/CancelReservationModal";

  export default {
    name: "ReservationDetail",
    title: "Prenotazione nel dettaglio",
    components: {CashSummaryTable, ContainerWithMenu, SelectCountry, Datetime, VuePhoneNumberInput, CancelReservationModal},
    mixins: [baseMixin],
    data: () => {
      return {
          loading: false,
        reservation: {},
        reservation_id: null,
        COUNTRIES: COUNTRIES,
          updatingReservation: false,
          TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
          phone_number_international: null,
          paymentOptions: [],
          visibleCancelReservationModal: false
      }
    },
    mounted(){
        this.paymentOptions = this.Keys.PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS();
        this.reservation_id = this.$route.params.reservation_id;
        this.getReservationDetail(this.reservation_id)

    },
    methods: {
        updatePhoneNumber(result) {
            this.phone_number_international = result.formatInternational;
        },
        countryChanged(newCountry) {
            this.reservation.customer_nationality= newCountry;
        },
        async updateReservation() {
            this.loading = true;
            try {
                this.reservation = await updateReservation(
                    this.reservation_id,
                    this.reservation.customer_first_name,
                    this.reservation.customer_last_name,
                    this.reservation.customer_email,
                    this.phone_number_international,
                    this.reservation.customer_nationality,
                    this.reservation.payment_mode,
                    this.reservation.date,
                    this.reservation.cancel_reason
                );

                this.$route.params.successMessage = "Prenotazione aggiornata con successo";
                this.updatingReservation = false;
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
        },
        async cancelReservation(cancelReason) {
            this.loading = true;
            try {
                this.reservation = await cancelReservation(
                    this.reservation_id,
                    cancelReason
                );

                this.reservation.state = this.Keys.RESERVATION_STATES.CANCELED;

                this.$route.params.successMessage = "Prenotazione annullata con successo";
                this.updatingReservation = false;
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
            this.visibleCancelReservationModal = false
        },
      async getReservationDetail(id) {
        this.loading = true;
        try {
          this.reservation = await getReservationDetail(id);
          this.loading = false;
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    }
  }
</script>
