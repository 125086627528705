<template>

    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
            <button type="button" class="close" @click.prevent.stop="$emit('close-cancel-reservation-modal')"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Annulla prenotazione</h4>
        </template>
        <template v-slot:body>
            <form>
                <div class="form-group">
                    <label for="text-area-reason-cancel">Inserisci motivazione annullamento prenotazione</label>
                    <textarea class="form-control" id="text-area-reason-cancel" rows="3" maxlength="255" v-model="cancelReason"></textarea>
                </div>
            </form>
            <div v-show="errorCancelReason" class="alert alert-danger" style="margin: 10px 0;" id="cancel-reservation-error-message" role="alert"></div>
        </template>
        <template v-slot:footer>
            <a class="btn btn-danger btn-lg btn-block margin-bottom-30" id="cancel_reservation_btn" @click.prevent.stop="doCancelReason">Annulla Prenotazione</a>
            <a class="btn btn-default btn-lg btn-block"  @click.prevent.stop="$emit('close-cancel-reservation-modal')">Torna Indietro</a>
        </template>
    </ModalContainer>

</template>

<script>
    import ModalContainer from "./ModalContainer"
    import baseMixin from "../../common/baseMixin";
    export default {
        name: "CancelReservationModal",
        components: { ModalContainer },
        mixins: [baseMixin],
        props: {
            visible: {
                type: Boolean,
                required: true
            }
        },
        data: () => {
            return {
                cancelReason: "",
                errorCancelReason: false
            }
        },
        methods: {
            doCancelReason() {
                if (this.cancelReason !== "") {
                    this.$emit('positive-btn-cancel-reservation-modal', this.cancelReason)
                } else {
                    this.errorCancelReason=true
                }
            }
        },
        mounted() {
        }
    }
</script>
